import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./filters/filters"
import vuetify from "./plugins/vuetify";
import "./plugins/base";
import "./plugins/vee-validate";
import VuetifyConfirm from "vuetify-confirm";
import VueApexCharts from "vue-apexcharts";
import excel from 'vue-excel-export'
import Vuetify from 'vuetify';

Vue.use(excel)
Vue.component("apexchart", VueApexCharts);
Vue.use(VuetifyConfirm, { vuetify });
Vue.config.productionTip = false;

Vue.config.errorHandler = error => {
  store.dispatch("error", error);
};

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
