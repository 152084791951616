var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-navigation-drawer', _vm._b({
    attrs: {
      "mobile-breakpoint": "960",
      "width": "250",
      "light": "",
      "app": ""
    },
    model: {
      value: _vm.drawer,
      callback: function ($$v) {
        _vm.drawer = $$v;
      },
      expression: "drawer"
    }
  }, 'v-navigation-drawer', _vm.$attrs, false), [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item', {
    staticStyle: {
      "border": "none !important"
    }
  }, [_c('a', {
    attrs: {
      "href": "/orders/search"
    }
  }, [_c('img', {
    staticClass: "logo",
    attrs: {
      "src": require("../../assets/mango-header-logo.png")
    }
  })])]), _c('v-list-item', {
    staticClass: "justify-center",
    staticStyle: {
      "border": "none !important"
    }
  }, [_vm.isDevEnvironment ? _c('h2', {
    staticClass: "orange--text font-weight-bold",
    domProps: {
      "textContent": _vm._s('Test Version')
    }
  }) : _vm._e()])], 1), _vm.permissionLoader ? _c('div', {
    staticClass: "sidemenu-loader"
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "color": "#f4846e"
    }
  })], 1) : _vm._e(), !_vm.permissionLoader ? _c('v-list', {
    staticStyle: {
      "border-top": "1px solid #ebebeb"
    },
    attrs: {
      "nav": ""
    }
  }, [_vm._l(_vm.routes, function (route, i) {
    return _c('v-list-item-group', {
      key: i,
      staticClass: "font-weight-bold navi",
      attrs: {
        "active-class": " primary--text"
      }
    }, [route.children && route.children.length > 0 ? _c('v-list-group', {
      attrs: {
        "prepend-icon": route.meta.icon,
        "group": route.path,
        "no-action": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function () {
          return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(route.meta.title))])], 1)];
        },
        proxy: true
      }], null, true)
    }, _vm._l(route.children, function (child, f) {
      return _c('v-list-item', {
        key: f,
        staticClass: "ml-4 pl-2",
        attrs: {
          "to": route.path + '/' + child.path
        }
      }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v(_vm._s(child.meta.icon))])], 1), _c('v-list-item-title', [_vm._v(_vm._s(child.meta.title))])], 1);
    }), 1) : _vm._e(), !route.children ? _c('v-list-item', {
      attrs: {
        "link": "",
        "to": route.path
      }
    }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v(_vm._s(route.meta.icon))])], 1), _c('v-list-item-title', [_vm._v(_vm._s(route.meta.title))])], 1) : _vm._e()], 1);
  }), _c('v-list-item-group', {
    staticClass: "font-weight-bold navi"
  }, [_c('a', {
    staticStyle: {
      "text-decoration": "none",
      "font-weight": "0"
    },
    attrs: {
      "href": "https://trainplanet.sharepoint.com/:x:/s/backofficeadmin_team/EfFlPZqBuEFKgZLY1B01Lb0BEtUbSjnzWZcl7HcPFAmuxQ",
      "target": "_blank"
    }
  }, [_vm.isBackOfficeAdmin ? _c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-file-document")])], 1), _c('v-list-item-title', [_vm._v("Complimentary Pass")])], 1) : _vm._e()], 1)])], 2) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }