import {ROLES} from "@/store/modules/roles";

const accountingRoutesRoles = [ROLES.ACCOUNTING, ROLES.ADMIN_BACK_OFFICE, ROLES.ADMIN];
const accountingRoutesScopes = [process.env.VUE_APP_MICROSOFT_API_URL + "/Scope.Accounting"];

export default {
  path     : "/accounting",
  name     : "accounting",
  component: () => import("@/views/Accounting/Index.vue"),
  props    : false,
  meta     : {
    title     : "Accounting",
    icon      : "mdi-chart-gantt",
    menus     : ["nav"],
    showInMenu: true,
    roles     : accountingRoutesRoles
  },
  children : [
    {
      path     : "paper-report",
      name     : "paper-report",
      props    : true,
      component: () => import("@/views/Accounting/PaperReport.vue"),
      meta     : {
        title     : "Paper Report",
        showInMenu: true,
        icon      : "mdi-note-multiple-outline",
        scopes    : accountingRoutesScopes,
        roles     : accountingRoutesRoles
      }
    },
    {
      path     : "invoices",
      name     : "accounting-invoices",
      props    : true,
      component: () => import("@/views/Accounting/Invoices.vue"),
      meta     : {
        title     : "Invoice Report",
        showInMenu: true,
        icon      : "mdi-receipt-text-outline",
        scopes    : accountingRoutesScopes,
        roles     : accountingRoutesRoles
      }
    },
    {
      path     : "order-accounting",
      name     : "order-accounting",
      props    : false,
      component: () => import("@/views/Accounting/Statistics.vue"),
      meta     : {
        title     : "Order Accounting",
        icon      : "mdi-chart-areaspline",
        showInMenu: true,
        menus     : ["nav"],
        scopes    : accountingRoutesScopes,
        roles     : accountingRoutesRoles
      }
    }
  ]
};
