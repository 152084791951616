import { ROLES } from "@/store/modules/roles";

const wikiRoles = Object.values(ROLES);
const wikiScopes = [process.env.VUE_APP_MICROSOFT_API_URL + "/Scope.Order.Basic"];

export default {
  path: "/wiki",
  name: "wiki",
  component: () => import("@/views/Wiki/index.vue"),
  meta: {
    title: "Wiki",
    icon: "mdi-bookshelf",
    showInMenu: true,
    menus: ["nav"],
    scopes: wikiScopes,
    roles: wikiRoles,
  },
};
