<template>
  <v-container fluid>
    <v-row align="center">
      <v-col cols="6">
        <v-select
          solo
          v-model="e2"
          :items="states"
          append-outer-icon="mdi-map"
          menu-props="auto"
          hide-details
          label="Select"
          single-line
        ></v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      e2: "Texas",
      states: ["Alabama", "Alaska", "American Samoa", "Wisconsin", "Wyoming"]
    };
  }
};
</script>
<style lang=""></style>
