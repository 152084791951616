var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "solo": "",
      "items": _vm.states,
      "append-outer-icon": "mdi-map",
      "menu-props": "auto",
      "hide-details": "",
      "label": "Select",
      "single-line": ""
    },
    model: {
      value: _vm.e2,
      callback: function ($$v) {
        _vm.e2 = $$v;
      },
      expression: "e2"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }