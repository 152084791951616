import Vue from "vue";
import Vuetify from "vuetify";

import "vuetify/dist/vuetify.min.css";

/**
 * https://vuetifyjs.com/en/
 */
const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#f37a62",
        third: "#F8B559",
        internal: "#FFF6D9",
        greeny: "#86C589",
        secondary: "#444",
        titlebg: "#333",
        accent: "#507DBC",
        info: "#00CAE3",
        danger: "#EB5E5E",
        disabled: "#f8f8f8",
        activeblue: "#e1f2ff",
        white: "#fff",
      },
      dark: {
        primary: "#f37a62",
        secondary: "#444",
        accent: "#f37a62",
        info: "#00CAE3",
        danger: "#fe0000"
      }
    },
    dark: false
  }
});

Vue.use(Vuetify);

export default vuetify;
