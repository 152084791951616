import {ROLES} from "@/store/modules/roles";

const systemRoutesScopes = [process.env.VUE_APP_MICROSOFT_API_URL + "/Scope.Admin"];
const systemRoutesRoles = [ROLES.ADMIN];

export default {
  path     : "/system",
  name     : "system-management",
  props    : false,
  component: () => import(/* webpackChunkName: "orders" */ "@/views/Management/Index.vue"),
  meta     : {
    title     : "System",
    icon      : "mdi-shield-crown",
    menus     : ["nav"],
    showInMenu: true,
    roles     : systemRoutesRoles
  },
  children : [
    {
      path     : "tenant",
      name     : "tenant-management",
      props    : true,
      component: () => import("@/views/TenantManagement/TenantManagement.vue"),
      meta     : {
        title     : "Tenants",
        showInMenu: true,
        icon      : "mdi-domain",
        scopes    : systemRoutesScopes,
        menus     : ["nav"],
        roles     : systemRoutesRoles
      },
      children : []
    },
    {
      path     : "settings",
      name     : "orders-settings",
      props    : true,
      component: () =>
        import(
          /* webpackChunkName: "orders-settings" */ "@/views/Management/SystemSettings/Settings.vue"
          ),
      meta     : {
        title     : "Settings",
        showInMenu: true,
        icon      : "mdi-cog-outline",
        scopes    : systemRoutesScopes,
        menus     : ["nav"],
        roles     : systemRoutesRoles
      },
      children : []
    }
  ]
};
