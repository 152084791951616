export default [
  {
    path     : "/",
    name     : "dashboard",
    component: () => import("@/views/Dashboard.vue"),
    meta     : {
      title     : "Welcome to Mango",
      icon      : "mdi-view-dashboard",
      showInMenu: false,
      scopes    : [process.env.VUE_APP_MICROSOFT_API_URL + "/Scope.Order.Basic"]
      // menus: ['nav'],
    },
    children : []
  },
  {
    path     : "/login",
    name     : "login",
    props    : false,
    component: () => import("@/views/Login.vue"),
    meta     : {
      title: "Login"
    },
    children : []
  },
  {
    path: "/page-not-found",
    name: "page404",
    component: () => import("@/views/ErrorPages/404.vue"),
    meta: { title: "404 Page Not Found" }
  },
  {
    path: "*",
    redirect: "/page-not-found",
    meta: { title: "404 Page Not Found" }
  }
];
