var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app-bar', {
    attrs: {
      "app": "",
      "short": ""
    }
  }, [_c('v-btn', {
    staticClass: "mr-3",
    attrs: {
      "elevation": "1",
      "fab": "",
      "small": ""
    },
    on: {
      "click": function ($event) {
        return _vm.setDrawer(!_vm.drawer);
      }
    }
  }, [_vm.drawer ? _c('v-icon', [_vm._v(" mdi-dots-horizontal ")]) : _c('v-icon', [_vm._v(" mdi-dots-vertical ")])], 1), _c('v-breadcrumbs', {
    staticClass: "pl-2",
    attrs: {
      "items": _vm.breadcrumbs,
      "large": ""
    },
    scopedSlots: _vm._u([{
      key: "divider",
      fn: function () {
        return [_c('v-icon', [_vm._v("mdi-chevron-right")])];
      },
      proxy: true
    }])
  }), _c('v-spacer'), _c('v-text-field', {
    staticClass: "font-weight-bold mt-7",
    staticStyle: {
      "max-width": "260px",
      "margin-right": "20px"
    },
    attrs: {
      "solo": "",
      "autofocus": _vm.focusToSearchComponent,
      "prepend-inner-icon": "mdi-magnify",
      "placeholder": "Search for orders...",
      "persistent-hint": "",
      "dense": ""
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.search(_vm.value);
      }
    },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }), _c('v-menu', {
    attrs: {
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var _vm$user$fullName;
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          staticClass: "text-capitalize",
          attrs: {
            "plain": ""
          }
        }, on), [_c('span', [_vm._v(_vm._s((_vm$user$fullName = _vm.user.fullName) !== null && _vm$user$fullName !== void 0 ? _vm$user$fullName : _vm.user.name))]), _c('v-icon', {
          attrs: {
            "right": "",
            "dark": ""
          }
        }, [_vm._v("mdi-chevron-down")])], 1)];
      }
    }])
  }, [_c('v-list', [_vm._l(_vm.routes, function (route, i) {
    return _c('v-list-item', {
      key: i
    }, [_c('v-list-item-title', [_vm._v(_vm._s(route.name))])], 1);
  }), _c('v-list-item', {
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.logout();
      }
    }
  }, [_c('v-list-item-title', [_vm._v("Logout")])], 1)], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }