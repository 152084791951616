var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "solo": "",
      "items": _vm.data,
      "item-text": _vm.itemText,
      "item-value": _vm.itemValue,
      "menu-props": {
        maxHeight: '400'
      },
      "label": "Select tenants",
      "multiple": "",
      "hint": "Please select tenant",
      "persistent-hint": ""
    },
    model: {
      value: _vm.selectedValues,
      callback: function ($$v) {
        _vm.selectedValues = $$v;
      },
      expression: "selectedValues"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }