import {ROLES} from "@/store/modules/roles";

const printRoutesRoles = [ROLES.ORDER_PRINT, ROLES.ADMIN_BACK_OFFICE, ROLES.ADMIN];
const printRoutesScopes = [process.env.VUE_APP_MICROSOFT_API_URL + "/Scope.Order.Basic"];

export default {
  path     : "/print",
  name     : "prints",
  props    : false,
  component: () => import(/* webpackChunkName: "orders" */ "@/views/Orders/Index.vue"),
  meta     : {
    title     : "Print",
    icon      : "mdi-printer",
    menus     : ["nav"],
    showInMenu: true,
    roles     : printRoutesRoles
  },
  children : [
    {
      path     : "print",
      name     : "orders-print",
      props    : true,
      component: () =>
        import(/* webpackChunkName: "orders-print" */ "@/views/Orders/Order/Print.vue"),
      meta     : {
        title     : "Print Orders",
        showInMenu: true,
        icon      : "mdi-printer-outline", // outline not working?
        scopes    : printRoutesScopes,
        roles     : printRoutesRoles
      }
    },
    {
      path     : "paper-storage",
      name     : "paper-storage",
      props    : true,
      component: () =>
        import(/* webpackChunkName: "orders-print" */ "@/views/Print/PaperStorage.vue"),
      meta     : {
        title     : "Paper Storage",
        showInMenu: true,
        icon      : "mdi-note-outline", // outline not working?
        scopes    : printRoutesScopes,
        roles     : printRoutesRoles
      }
    }
  ]
};
