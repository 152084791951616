import {ROLES} from "@/store/modules/roles";

const giftCardRoutesRoles = [ROLES.GIFT_CARDS, ROLES.ADMIN_BACK_OFFICE, ROLES.ADMIN];
const giftCardRoutesScopes = [process.env.VUE_APP_MICROSOFT_API_URL + "/Scope.GiftCards"];


export default {
  path     : "/gift-card",
  name     : "gift-card",
  props    : false,
  component: () => import("@/views/Orders/Index.vue"),
  meta     : {
    title     : "Gift Cards",
    icon      : "mdi-gift",
    menus     : ["nav"],
    showInMenu: true,
    roles     : giftCardRoutesRoles
  },
  children : [
    {
      path     : "check",
      name     : "check-giftcard",
      props    : true,
      component: () => import("@/views/GiftCard/CheckGiftCard/CheckGiftCard.vue"),
      meta     : {
        title     : "Check Gift Card",
        showInMenu: true,
        icon      : "mdi-check",
        scopes    : giftCardRoutesScopes,
        roles     : giftCardRoutesRoles
      }
    },
    {
      path     : "compensation",
      name     : "compensation-cards",
      props    : true,
      component: () => import("@/views/GiftCard/CompensationGiftCards/CompensationGiftCards.vue"),
      meta     : {
        title     : "Compensation Cards",
        showInMenu: true,
        icon      : "mdi-wallet-giftcard ",
        scopes    : giftCardRoutesScopes,
        roles     : giftCardRoutesRoles
      }
    },
    {
      path     : "staff",
      name     : "staff-cards",
      props    : true,
      component: () => import("@/views/GiftCard/StaffGiftCards/StaffGiftCards.vue"),
      meta     : {
        title     : "Staff Cards",
        showInMenu: true,
        icon      : "mdi-gift-outline",
        scopes    : giftCardRoutesScopes,
        roles     : giftCardRoutesRoles
      }
    }
  ]
};
