const actions = {
  loading: ({commit}, loading) => {
    commit("setLoadingState", loading);
  },
  error: ({commit}, error) => {
    commit("setErrorState", error);
    if (error != null) {
      console.error(error);
    }
  },
  success: ({commit}, msg) => {
    commit("setSuccessState", msg);
  }
};

export default {
  actions
};
