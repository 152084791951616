import {ROLES} from "@/store/modules/roles";

export default {
  path     : "/task-management",
  name     : "task-management",
  props    : true,
  component: () => import("@/views/Management/TaskManagement/TaskManagement.vue"),
  meta     : {
    title     : "Tasks",
    showInMenu: true,
    icon      : "mdi-format-list-checks",
    scopes    : [process.env.VUE_APP_MICROSOFT_API_URL + "/Scope.Tasks"],
    menus     : ["nav"],
    roles     : [ROLES.TASKS, ROLES.ADMIN_BACK_OFFICE, ROLES.ADMIN]
  }
};
