const mutations = {
  setLoadingState: (state, loading) => {
    state.loading = loading;
  },
  setErrorState: (state, error) => {
    state.error = error;
  },
  setSuccessState: (state, message) => {
    state.success = message;
  },
  SET_DRAWER(state, payload) {
    state.drawer = payload;
  }
};

export default {
  mutations
};
