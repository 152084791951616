import * as msal from "@azure/msal-browser";
import Trainplanet from "./../../util/trainplanet.api";
import {ROLES} from "@/store/modules/roles";

const {origin} = window.location;

/**
 *
 * Login via Microsoft
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/dev/lib/msal-browser
 *
 */
const microsoft = new msal.PublicClientApplication({
  auth  : {
    clientId   : process.env.VUE_APP_MICROSOFT_CLIENT_ID,
    authority  : `https://login.microsoftonline.com/${process.env.VUE_APP_MICROSOFT_TENANT_ID}`, // Only users in our tenant is allowed.
    redirectUri: `${origin}`
    // postLogoutRedirectUri: `${origin}/login`,
  },
  cache : {
    cacheLocation         : "localStorage", // "localStorage will give you Single Sign On across tabs and user sessions"
    storeAuthStateInCookie: true
  },
  system: {
    allowRedirectInIframe: true
  }
});

/**
 *
 *
 * Vuex Store
 *
 */
const vuex = {};

vuex.state = {
  userId     : localStorage.getItem("userId") || null,
  user       : null,
  userRoles  : [],
  userTenants: [],
  routes     : [],
};

vuex.getters = {
  isAuthenticated: (state, getters) => !!getters.user,
  user           : state => {
    if (state.user) return state.user;
    if (state.userId) return microsoft.getAccountByHomeId(state.userId);
    return null;
  },
  userRoles      : state => {
    return state.userRoles;
  },
  userId         : state => {
    return state.userId;
  },
  userTenants    : state => {
    return state.userTenants;
  },
  routes         : state => {
    return state.routes;
  },

  // custom permission methods
  isSuperAdmin     : state => {
    return state.userRoles && state.userRoles.includes(ROLES.ADMIN);
  },
  isBackOfficeAdmin: state => {
    const roles = [ROLES.ADMIN_BACK_OFFICE, ROLES.ADMIN];

    return state.userRoles && state.userRoles.some(role => roles.includes(role));
  },
  canInvoiceOrders : state => {
    const roles = [ROLES.INVOICING, ROLES.ADMIN_BACK_OFFICE, ROLES.ADMIN];

    return state.userRoles && state.userRoles.some(role => roles.includes(role));
  }
};

vuex.actions = {
  /**
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md
   */
  async getUser({commit, rootState}) {

    if (!rootState.allTenants || rootState.allTenants.length === 0) {
      rootState.allTenants = await Trainplanet.getAllTenants();
    }

    const res = await Trainplanet.getUser();

    commit("setUserRoles", res.roles);

    const userTenants = [];
    res.tenants.forEach(tenantId => {
      // if we received tenants as objects instead of ID strings, just push
      if (typeof tenantId !== 'string' && !(tenantId instanceof String)) {
        userTenants.push(tenantId);
        return;
      }

      const foundTenantObject = rootState.allTenants.find(tenant => tenant.id === tenantId);
      if (foundTenantObject) {
        userTenants.push(foundTenantObject);
      }
    });
    commit("setUserTenants", userTenants);

    return res;
  },

  async login({commit, dispatch}) {
    const response = await microsoft.loginPopup({});
    commit("setUser", response.account);
  },

  /**
   *
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/acquire-token.md
   *
   */
  async acquireToken({commit, getters, dispatch}, scopes) {
    const account = getters.user;
    const request = {
      scopes,
      account
    };

    try {
      const response = await microsoft.acquireTokenSilent(request);

      commit("setUser", response.account);

      return response.accessToken;
    } catch (error) {
      if (error instanceof msal.InteractionRequiredAuthError) {
        console.info("microsoft acquireToken InteractionRequiredAuthError");
        const response = await microsoft.acquireTokenPopup(request);
        commit("setUser", response.account);
        return response.accessToken;
      }

      throw new Error(error);
    }
  },

  async checkNavigationAuthentication({commit, getters}, scopes) {
    const account = getters.user;
    const request = {
      scopes,
      account
    };
    try {
      const response = await microsoft.acquireTokenSilent(request);
      return response.accessToken;
    } catch (error) {
      return error;
    }
  },

  /**
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/logout.md
   */
  async logout({commit}) {
    await microsoft.logoutPopup({
      mainWindowRedirectUri: `${origin}/login`
    });
    commit("removeUser");
  }
};

vuex.mutations = {
  setUser(state, account) {
    const {homeAccountId} = account;

    state.user = microsoft.getAccountByHomeId(homeAccountId);
    state.userId = homeAccountId;
    localStorage.setItem("userId", homeAccountId);
  },
  removeUser(state) {
    state.userId = null;
    state.userRoles = [];
    state.userTenants = [];
    localStorage.removeItem("_user_mango_");
    localStorage.removeItem("userId");
    localStorage.removeItem("mango_search_filters");  // clear search filters too when logged out
  },
  setUserRoles(state, roles) {
    state.userRoles = roles;
  },
  setRoutes(state, routes) {
    state.routes = routes;
  },
  setUserTenants(state, userTenants) {
    state.userTenants = userTenants;
  },
};

export default {
  namespaced: true,
  state     : vuex.state,
  getters   : vuex.getters,
  mutations : vuex.mutations,
  actions   : vuex.actions
};
