var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_vm.isAuthenticated ? _c('Navigation') : _vm._e(), _vm.isAuthenticated ? _c('Header') : _vm._e(), _c('v-main', [_c('v-container', {
    staticClass: "pa-5 mb-15",
    attrs: {
      "fluid": "",
      "fill-height": !_vm.isAuthenticated
    }
  }, [_c('router-view'), _c('v-dialog', {
    attrs: {
      "value": _vm.currentRoute.query.authstate === 'retry',
      "max-width": "600"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v("Login expired")]), _c('v-card-text', [_c('p', [_vm._v(" Your login session has expired. Please renew the session to keep working. ")])]), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "loading": _vm.authenticating,
      "color": "accent",
      "block": ""
    },
    on: {
      "click": function ($event) {
        return _vm.renewSession();
      }
    }
  }, [_vm._v("Renew session")])], 1)], 1)], 1), _c('v-snackbar', {
    attrs: {
      "value": _vm.error,
      "min-width": "calc(100% - 10px)",
      "timeout": -1,
      "color": "red",
      "top": "",
      "multi-line": "",
      "app": ""
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function (_ref) {
        var attrs = _ref.attrs;
        return [_c('v-btn', _vm._b({
          attrs: {
            "icon": ""
          },
          on: {
            "click": function ($event) {
              _vm.error = null;
            }
          }
        }, 'v-btn', attrs, false), [_c('v-icon', [_vm._v("mdi-close")])], 1)];
      }
    }])
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto"
    }
  }, [_c('v-icon', {
    attrs: {
      "large": ""
    }
  }, [_vm._v("mdi-alert-octagon-outline")])], 1), _c('v-col', [_c('h3', [_vm._v("Error")]), _c('code', {
    staticClass: "text-subtitle-1"
  }, [_vm._v(_vm._s(_vm.error))])])], 1)], 1), _c('v-overlay', {
    attrs: {
      "value": _vm.loading,
      "absolute": "",
      "color": "white",
      "opacity": 0.8
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "size": "64",
      "color": "accent"
    }
  })], 1), _c('v-snackbar', {
    attrs: {
      "value": _vm.success,
      "app": "",
      "color": "success",
      "min-width": "calc(100% - 10px)",
      "multi-line": "",
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function (_ref2) {
        var attrs = _ref2.attrs;
        return [_c('v-btn', _vm._b({
          attrs: {
            "icon": ""
          },
          on: {
            "click": function ($event) {
              _vm.success = null;
            }
          }
        }, 'v-btn', attrs, false), [_c('v-icon', [_vm._v("mdi-close")])], 1)];
      }
    }])
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto"
    }
  }, [_c('v-icon', {
    attrs: {
      "large": ""
    }
  }, [_vm._v("mdi-check-circle")])], 1), _c('v-col', [_c('h3', [_vm._v("Success")]), _c('code', {
    staticClass: "text-subtitle-1"
  }, [_vm._v(_vm._s(_vm.success))])])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }