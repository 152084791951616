import {ROLES} from "@/store/modules/roles";

const contentRoutesRolesBasic = [ROLES.ADMIN_BACK_OFFICE, ROLES.ADMIN];
const contentRoutesRolesElevated = [ROLES.ADMIN];

const contentRoutesScopesBasic = [process.env.VUE_APP_MICROSOFT_API_URL + "/Scope.Admin.BackOffice"];
const contentRoutesScopesElevated = [process.env.VUE_APP_MICROSOFT_API_URL + "/Scope.Admin"];

export default {
  path     : "/content",
  name     : "content-management",
  props    : false,
  component: () => import(/* webpackChunkName: "orders" */ "@/views/ContentManager/Index.vue"),
  meta     : {
    title     : "Contents",
    icon      : "mdi-file-document-edit",
    menus     : ["nav"],
    showInMenu: true,
    roles     : contentRoutesRolesBasic
  },
  children : [
    {
      path     : "tos",
      name     : "terms-of-services",
      props    : true,
      component: () => import("@/views/ContentManager/TermsOfService/TermsOfServices.vue"),
      meta     : {
        title     : "Terms & Conditions",
        showInMenu: true,
        icon      : "mdi-script-text-outline",
        menus     : ["nav"],
        scopes    : contentRoutesScopesBasic,
        roles     : contentRoutesRolesBasic
      },
      children : []
    },
    {
      path     : "helpcenter",
      props    : false,
      component: () =>
        import(
          /* webpackChunkName: "content-helpcenter" */ "@/views/ContentManager/HelpCenter/Index.vue"
          ),
      meta     : {
        title     : "FAQ",
        showInMenu: true,
        icon      : "mdi-help-circle",
        scopes    : contentRoutesScopesBasic,
        roles     : contentRoutesRolesBasic
      }
    },
    {
      path     : 'web',
      props    : false,
      component: () => import(/* webpackChunkName: "content-web" */ '@/views/ContentManager/Web/Index.vue'),
      meta     : {
        title     : 'Web Pages',
        showInMenu: true,
        icon      : 'mdi-web',
        scopes    : contentRoutesScopesElevated,
        roles     : contentRoutesRolesElevated
      },
      children : [
        {
          path     : '',
          name     : 'list-pages',
          props    : false,
          component: () => import(/* webpackChunkName: "content-web-pages-list" */ '@/views/ContentManager/Web/ListPages.vue'),
          meta     : {
            title     : 'Pages',
            showInMenu: false,
            icon      : 'mdi-basket-plus-outline',
            scopes    : contentRoutesScopesElevated,
            roles     : contentRoutesRolesElevated
          },
        },
        {
          path     : 'create',
          name     : 'create-page',
          props    : false,
          component: () => import(/* webpackChunkName: "content-web-pages-create" */ '@/views/ContentManager/Web/CreatePage.vue'),
          meta     : {
            title     : 'Create page',
            showInMenu: false,
            icon      : 'mdi-basket-plus-outline',
            scopes    : contentRoutesScopesElevated,
            roles     : contentRoutesRolesElevated
          },
        },
      ],
    },
    {
      path     : "files",
      props    : false,
      component: () =>
        import(
          /* webpackChunkName: "content-helpcenter" */ "@/views/ContentManager/FileManager/Index.vue"
          ),
      meta     : {
        title     : "File Manager",
        showInMenu: true,
        icon      : "mdi-file-document-edit",
        scopes    : contentRoutesScopesElevated,
        roles     : contentRoutesRolesElevated
      }
    },
  ]
};
