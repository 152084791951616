import {ROLES} from "@/store/modules/roles";

const companyRoutesRoles = [ROLES.INVOICING, ROLES.ADMIN_BACK_OFFICE, ROLES.ADMIN];
const companyRoutesScopes = [process.env.VUE_APP_MICROSOFT_API_URL + "/Scope.Invoicing"];

export default {
  path     : "/company",
  name     : "company",
  component: () => import("@/views/Company/Index.vue"),
  props    : false,
  meta     : {
    title     : "Company",
    icon      : "mdi-domain",
    menus     : ["nav"],
    showInMenu: true,
    roles     : companyRoutesRoles
  },
  children : [
    {
      path     : "companies",
      name     : "companies",
      props    : true,
      component: () => import("@/views/Company/Company/CompanyList.vue"),
      meta     : {
        title     : "Company List",
        showInMenu: true,
        icon      : "mdi-domain",
        scopes    : companyRoutesScopes,
        roles     : companyRoutesRoles
      }
    },
    {
      path     : "invoices",
      name     : "invoices",
      props    : true,
      component: () => import("@/views/Company/Invoice/Invoice.vue"),
      meta     : {
        title     : "Invoices",
        showInMenu: true,
        icon      : "mdi-receipt-text-outline",
        scopes    : companyRoutesScopes,
        roles     : companyRoutesRoles
      }
    }
  ]
};
