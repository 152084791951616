import {ROLES} from "@/store/modules/roles";

const orderRoutesRoles = [ROLES.ORDER_BASIC, ROLES.ADMIN_BACK_OFFICE, ROLES.ADMIN];
const orderRoutesScopes = [process.env.VUE_APP_MICROSOFT_API_URL + "/Scope.Order.Basic"];

export default {
  path     : "/orders",
  name     : "orders",
  props    : false,
  component: () => import("@/views/Orders/Index.vue"),
  meta     : {
    title: "Orders",
    icon : "mdi-basket",
    menus: ["nav"]
  },
  children : [
    {
      path     : "create",
      name     : "orders-create",
      props    : false,
      component: () => import("@/views/Orders/Order/Create.vue"),
      meta     : {
        title     : "Create order",
        showInMenu: true,
        icon      : "mdi-basket-plus-outline",
        scopes    : orderRoutesScopes,
        roles     : orderRoutesRoles
      }
    },
    {
      path     : "search",
      name     : "orders-search",
      props    : route => ({...route.query, ...route.params}),
      component: () => import("@/views/Orders/Order/Search.vue"),
      meta     : {
        title     : "Search orders",
        showInMenu: true,
        icon      : "mdi-database-search-outline",
        scopes    : orderRoutesScopes,
        roles     : orderRoutesRoles
      }
    },
    {
      path     : "inquiries",
      props    : false,
      component: () => import("@/views/Orders/Inquiry/Index.vue"),
      meta     : {
        title     : "Claims",
        icon      : "mdi-forum-outline",
        showInMenu: true,
        scopes    : orderRoutesScopes,
        roles     : orderRoutesRoles
      },
      children : [
        {
          path     : "",
          name     : "inquiries-search",
          props    : false,
          component: () => import("@/views/Orders/Inquiry/Search.vue"),
          meta     : {
            title : "Search Claims",
            scopes: orderRoutesScopes,
            roles : orderRoutesRoles
          }
        },
        {
          path     : ":inquiryId",
          name     : "inquiries-edit",
          props    : true,
          component: () => import("@/views/Orders/Inquiry/Edit.vue"),
          meta     : {
            title : "Manage Claim",
            scopes: orderRoutesScopes,
            roles : orderRoutesRoles
          }
        }
      ]
    },
    {
      path     : ":orderId",
      name     : "orders-edit",
      props    : true,
      component: () => import("@/views/Orders/Order/Edit.vue"),
      meta     : {
        title     : "Edit order: :orderId",
        showInMenu: false,
        scopes    : orderRoutesScopes,
        roles     : orderRoutesRoles
      }
    }
  ]
};
