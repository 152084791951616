<template lang="">
  <v-container fluid>
    <v-row align="center">
      <v-col cols="12" sm="6">
        <v-select
          solo
          v-model="selectedValues"
          :items="data"
          :item-text="itemText"
          :item-value="itemValue"
          :menu-props="{ maxHeight: '400' }"
          label="Select tenants"
          multiple
          hint="Please select tenant"
          persistent-hint
        ></v-select>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  props: ["itemText", "itemValue", "data", "onChange"],
  data() {
    return {
      selectedValues: []
    };
  },
  watch: {
    selectedValues: function(val) {
      this.onChange(val);
    },
    data(data) {
      this.selectedValues = [data[0].id];
    }
  }
};
</script>
<style lang=""></style>
