<template>
  <v-app-bar app short>
    <v-btn class="mr-3" elevation="1" fab small @click="setDrawer(!drawer)">
      <v-icon v-if="drawer"> mdi-dots-horizontal </v-icon>

      <v-icon v-else> mdi-dots-vertical </v-icon>
    </v-btn>
    <v-breadcrumbs :items="breadcrumbs" class="pl-2" large>
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>

    <v-spacer></v-spacer>
    <v-text-field
      solo
      v-model="value"
      class="font-weight-bold mt-7"
      :autofocus="focusToSearchComponent"
      style="max-width:260px; margin-right:20px; "
      prepend-inner-icon="mdi-magnify"
      placeholder="Search for orders..."
      persistent-hint
      dense
      @keydown.enter="search(value)"
    ></v-text-field>
    <!-- <v-switch
          :label="$vuetify.theme.dark ? 'Dark' : 'Light'"
          v-model="$vuetify.theme.dark"
          class="ma-0 pa-0"
          color="secondary"
          hide-details
      /> -->

    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn plain class="text-capitalize" v-on="on">
          <span>{{ user.fullName ?? user.name }}</span>
          <v-icon right dark>mdi-chevron-down</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item v-for="(route, i) in routes" :key="i">
          <v-list-item-title>{{ route.name }}</v-list-item-title>
        </v-list-item>

        <v-list-item @click.stop="logout()">
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "Header",
  data: self => ({
    value: "",
    user: self.$store.getters["auth/user"],
    routes: self.$router.options.routes.filter(
      route => route.meta.menus && route.meta.menus.includes("header")
    ),
    focusToSearchComponent: false
  }),
  computed: {
    ...mapState(["drawer"]),
    breadcrumbs() {
      return this.$route.matched.map((route, index, { length }) => {
        return {
          text: route.meta.title,
          href: route.path,
          disabled: index + 1 === length || index === 0
        };
      });
    }
  },
  methods: {
    search(value) {
      window.location.replace(
        "/orders/search?page=1&itemsPerPage=30&mustSort=false&multiSort=false&query=" +
          value +
          "&dates&printable=false&paid=false&searchOnce=true"
      );
      this.focusToSearchComponent = false;
    },
    ...mapMutations({
      setDrawer: "SET_DRAWER"
    }),
    async logout() {
      await this.$store.dispatch("auth/logout");
    }
  }
};
</script>

<style>
.v-breadcrumbs li a {
  color: rgba(0, 0, 0, 0.6) !important;
}

.v-breadcrumbs li:last-of-type a {
  color: initial !important;
  font-weight: bold;
}
</style>
