import Vue from "vue";
import moment from "moment-timezone";
Vue.filter("cleanHtml", string => {
  return string.replace(/<\/?[^>]+>/gi, " ");
});

Vue.filter("localizeUTCAndGetDate", datetime => {
  return moment
    .utc(datetime)
    .local()
    .format("YYYY-MM-DD");
});
Vue.filter("localizeUTCAddDaysAndGetDate", (datetime, days) => {
  return moment
    .utc(datetime)
    .local()
    .add(days, 'days')
    .format("YYYY-MM-DD");
});
Vue.filter("localizeUTCAndGetTime", datetime => {
  return moment
    .utc(datetime)
    .local()
    .format("HH:mm");
});


Vue.filter("localizeSwedishTimestampAndGetDate", datetime => {
  return moment
    .tz(datetime, "Europe/Stockholm")
    .local()
    .format("YYYY-MM-DD");
});
Vue.filter("localizeSwedishTimestampAndGetTime", datetime => {
  return moment
    .tz(datetime, "Europe/Stockholm")
    .local()
    .format("HH:mm");
});
