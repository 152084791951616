import router from "@/router";
import store from "@/store";
const axios = require("axios");

// TrainPlanet API instance.
const api = axios.create({
	baseURL: process.env.VUE_APP_TRAINPLANET_API_URL_BASE,
	timeout: 3600000,  // 1 hour
});

/**
 *
 *
 * Request middleware.
 * Get and set credentials before each request.
 *
 *
 */

api.interceptors.request.use(async request => {
  const middleware = request;
  const token = await store.dispatch("auth/acquireToken", router.history.current.meta.scopes);
  /* eslint-disable dot-notation */
  middleware.headers["Authorization"] = `Bearer ${token}`;
  middleware.headers["Content-Type"] = "application/json";

  return middleware;
});

export default api;
