import api from "./api";

// API methods.
const Trainplanet = {
  /**
   * List Help Center
   */
  async listHelpCenter(params) {
    const { data } = await api.get("/helpcenter", {
      params: {
        inludeNotPublished: true,
        ...params,
      },
      headers: {
        "Accept-Language": "",
      },
    });
    return data;
  },

  /**
   * Create HelpCenter item
   */
  async createHelpCenter(parentId = null, item) {
    const params = parentId ? { params: { parentId } } : null;
    const { data } = await api.post("/helpcenter", item, params);
    return data;
  },

  /**
   * Update HelpCenter item
   */
  async updateHelpCenter(id, item) {
    const { data } = await api.patch(`/helpcenter/${id}`, item);
    return data;
  },

  /**
   * Delete HelpCenter item
   */
  async deleteHelpCenter(id) {
    await api.delete(`/helpcenter/${id}`);
  },

  /**
   * Get all supported options for the order system
   */
  async getOrderSystemOptions() {
    const { data } = await api.get("/orders/system/options");
    return data;
  },

  /**
   * Delete an object from the order system
   */
  async deleteOrderSystemObject(id) {
    await api.delete(`/orders/system/objects/${id}`);
  },

  /**
   * Create an article for the order system
   */
  async createOrderSystemObject(type, item) {
    const { data } = await api.post(`/orders/system/objects/${type}`, item);
    return data;
  },

  /**
   * Update an article in the order system
   */
  async updateOrderSystemObject(type, id, item) {
    const { data } = await api.patch(`/orders/system/objects/${type}/${id}`, item);
    return data;
  },

  /**
   * List all artices that can be used in an order
   */
  async listOrderSystemArticles(params) {
    const { data } = await api.get("/orders/system/objects/articles", {
      params,
    });
    return data;
  },

  /**
   * List all distributions that can be used in an order
   */
  async listOrderSystemDistributions(params) {
    const { data } = await api.get("/orders/system/objects/distributions", {
      params,
    });
    return data;
  },

  /**
   * Get a list of multiple orders.
   */
  async listOrders(filter = {}) {
    const { data, headers } = await api.get("/orders", {
      params: {
        ...filter,
      },
    });

    return {
      meta: {
        count: headers["x-total-count"],
      },
      data,
    };
  },

  /**
   * Get a single order.
   */
  async getOrder(orderId) {
    const { data } = await api.get(`/orders/${orderId}`);
    return data;
  },

  async getOrderAccount(orderId) {
    const { data } = await api.get(`/orders/${orderId}/account`);
    return data;
  },
  /**
   * Create an order
   */
  async createOrder(order, locale) {
    const { data } = await api.post("/orders", order, {
      headers: {
        "Accept-Language": locale,
      },
    });
    return data;
  },

  /**
   * Update an order
   */
  async updateOrder(orderId, order, replace = false, locale) {
    const { data } = await api.patch(`/orders/${orderId}`, order, {
      params: {
        replace,
      },
      headers: {
        "Accept-Language": locale,
        "Content-Type": "application/json",
      },
    });
    return data;
  },

  /**
   * Validation
   */
  async validateOrder(orderId, validate) {
    const { data } = await api.patch(`/orders/${orderId}/validate`, validate);
    return data;
  },

  /**
   * Print an order
   */
  async printOrder(orderId, distributionId, tickets) {
    const { data } = await api.post(`/orders/${orderId}/distributions/${distributionId}/shipment`, {
      tickets,
    });
    return data;
  },

  /**
   * Create a event for an order
   */
  async createOrderEvent(orderId, message) {
    const { data } = await api.post(`/orders/${orderId}/event`, {
      message,
    });
    return data;
  },

  /**
   * Send paylink to the customer
   */
  async sendOrderPaylink(orderId, locale, offerMode = false) {
    await api.post(`/orders/${orderId}/paylink`, null, {
      headers: {
        "Accept-Language": locale["value"] ?? locale,
      },
      params: {
        offerMode,
      },
    });
  },

  /**
   * Send confirmation to the customer
   */
  async sendConfirmationLink(orderId, locale) {
    await api.post(`/orders/${orderId}/confirmation`, null, {
      headers: {
        "Accept-Language": locale["value"] ?? locale,
      },
    });
  },

  async sendTicket(orderId, locale) {
    await api.post(`/orders/${orderId}/send-tickets`, null, {
      headers: {
        "Accept-Language": locale["value"] ?? locale,
      },
    });
  },

  async getOrderFiles(orderId, locale) {
    const { data } = await api.post(`/orders/${orderId}/send-tickets`, null, {
      headers: {
        "Accept-Language": locale["value"] ?? locale,
      },
      params: {
        getAsPdfLinks: true,
      },
    });
    return data;
  },

  /**
   * lock an order
   */
  async lock(orderId) {
    await api.post(`/orders/${orderId}/lock`);
  },

  /**
   *  unlock an order
   */
  async unlock(orderId) {
    return await api.post(`/orders/${orderId}/unlock`);
  },

  /**
   *
   *  archive an order
   */
  async archiveOrder(orderId) {
    const { data } = await api.patch(`/orders/${orderId}/archive`);
    return data;
  },

  /**
   * TODO: archive an order
   */
  async anonymizeOrder(orderId) {
    return await api.patch(`/orders/${orderId}/anonymize`);
  },

  /**
   * List inquiries
   */
  async listInquiries(filter = {}) {
    const { data, headers } = await api.get("/orders/inquiries", {
      params: {
        ...filter,
      },
    });

    return {
      meta: {
        count: headers["x-total-count"],
      },
      data,
    };
  },

  async getInquiry(inquiryId) {
    const { data } = await api.get(`/orders/inquiries/${inquiryId}`);
    return data;
  },

  async createInquiry(input) {
    const { data } = await api.post("/orders/inquiries", input);
    return data;
  },

  async updateInquiry(id, input) {
    const { data } = await api.patch("/orders/inquiries/" + id, input);
    return data;
  },

  async updateInquiryCustomerInfo(id, input) {
    const { data } = await api.put("/orders/inquiries/" + id + "/customer", input);
    return data;
  },

  async createInquiryEvent(inquiryId, input) {
    const { data } = await api.post(`/orders/inquiries/${inquiryId}/event`, input);
    return data;
  },

  async updateInquiryEvent(inquiryId, eventId, input) {
    const { data } = await api.patch(`/orders/inquiries/${inquiryId}/event/${eventId}`, input);
    return data;
  },

  async deleteInquiryEvent(inquiryId, eventId) {
    const { data } = await api.delete(`/orders/inquiries/${inquiryId}/event/${eventId}`);
    return data;
  },

  async changeStatusInquiry(inquiryId, input) {
    return await api.patch(`/orders/inquiries/${inquiryId}/status`, input);
  },

  async changeZendeskId(inquiryId, input) {
    return await api.patch(`/orders/inquiries/${inquiryId}/${input.zendeskId}`, input);
  },

  async addCancelInfo(orderId, itemId, input) {
    return await api.patch(`/orders/${orderId}/cancel/${itemId}`, input);
  },
  async manualRefund(orderId, dataForm, locale) {
    const { data } = await api.post(`/orders/${orderId}/manualRefund`, dataForm, {
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": locale,
      },
    });
    return data;
  },

  async deleteItems(orderId, { itemIds }) {
    const { data } = await api.delete(`/orders/${orderId}/item`, {
      params: {
        itemIds,
      },
    });
    return data;
  },

  async lockOrderItem(orderId, itemId) {
    const { data } = await api.post(`/orders/${orderId}/item/${itemId}/lock`);
    return data;
  },

  async CancelAndRefund(orderId, payload) {
    const { data } = await api.post(`/orders/${orderId}/refund`, payload);
    return data;
  },

  async cancelItems(orderId, payload) {
    const { data } = await api.post(`/orders/${orderId}/cancel`, payload);
    return data;
  },

  async getRefundAmount(orderId, payload) {
    const { data } = await api.post(`/orders/${orderId}/refund-amount`, payload);
    return data;
  },

  async getStatistics(params) {
    const { data } = await api.get("/orders/accounting", {
      params,
    });
    return data;
  },

  async getStatisticsExcel(data, params) {
    return await api.post("/orders/accounting/excel", data, {
      params,
      responseType: "blob",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  async addItems(orderId, items) {
    const { data } = await api.post(`/orders/${orderId}/item`, items);
    return data;
  },
  async lockDistribution(orderId, distributionId, reason) {
    const { data } = await api.post(`/orders/${orderId}/distributions/${distributionId}/lock`, {
      reason,
    });
    return data;
  },

  async cancelDistribution(orderId, distributionId) {
    const { data } = await api.delete(`/orders/${orderId}/distributions/${distributionId}/shipment`);
    return data;
  },

  async getPapers() {
    const { data } = await api.get("/papers");
    return data.boxes;
  },

  async createBox(body) {
    const { data } = await api.post("/papers", body);
    return data;
  },

  async getPapersReport(params) {
    const { data } = await api.get("/papers/report", { params });
    return data;
  },

  async getPapersReportExcel(params) {
    const data = await api.get("/papers/report-excel", { params, responseType: "blob" });
    return data;
  },

  /**
   *
   *
   * Get Tenant Details
   *
   *
   */
  async getAllTenants() {
    const { data } = await api.get("/tenants");
    return data;
  },

  async getTenantDetail(tenantId) {
    const { data } = await api.get("/tenants/manage", { params: { tenantId } });
    return data;
  },

  /**
   *
   * Update Tenants
   *
   */

  async updateTenant(tenant) {
    const { data } = await api.patch("/tenants/manage", tenant);
    return data;
  },
  async saveNewTenant(tenant) {
    const { data } = await api.post("/tenants/manage", tenant);
    return data;
  },

  /**
   * Get Systems
   */
  async getSystemObjects(tenantId) {
    const { data } = await api.get("/orders/system/objects");
    return data;
  },

  /**
   *
   * Get Users
   *
   */

  async getUser() {
    const { data } = await api.get("/user");
    return data;
  },

  /**
   *
   * Terms Of Services
   *
   */

  async getTermsOfServices(tenantIds) {
    const res = await api.get("/tenants/tos", { params: tenantIds });
    return res.data;
  },

  async createTranslationForTermsOfService({ tenantId, body }) {
    const res = await api.post(`/tenants/tos/${tenantId}`, body);
    return res.data;
  },

  async updateTranslationForTermsOfService({ tenantId, translationId, body }) {
    const res = await api.patch(`/tenants/tos/${tenantId}${translationId}`, body);
    return res.data;
  },

  async deleteTranslationForTermsOfService(tenantId, translationId) {
    const res = await api.delete(`/tenants/tos/${tenantId}${translationId}`);
    return res.data;
  },

  /**
   *
   *
   * Tasks
   *
   *
   **/

  async getTasks(tenantIds) {
    const res = await api.get("/tasks", { params: { tenantIds } });
    return res.data;
  },

  async completeTask(taskId) {
    const res = await api.put(`/tasks/${taskId}/complete`);
    return res.data;
  },

  async incompleteTask(taskId) {
    const res = await api.put(`/tasks/${taskId}/incomplete`);
    return res.data;
  },

  async addNewTask(body) {
    const res = await api.post(`/tasks`, body);
    return res.data;
  },

  async deleteTask(taskId) {
    const res = await api.delete(`/tasks/${taskId}`);
    return res.data;
  },

  async updateTask(taskId, body) {
    const res = await api.patch(`/tasks/${taskId}`, body);
    return res.data;
  },

  async getUsers(searchQuery) {
    const params = searchQuery ? { q: searchQuery } : null;
    const res = await api.get(`/user/list`, { params });
    return res.data;
  },

  /**
   *
   * Gift Card
   *
   */

  async listSpecialGiftCards(params) {
    const res = await api.get(`/gift-cards/special-card/list`, { params });
    return res.data;
  },

  async createSpecialGiftCard(body) {
    const res = await api.post(`/gift-cards/special-card`, body);
    return res.data;
  },

  async approveSpecialGiftCard(cardCode) {
    const res = await api.patch(`/gift-cards/special-card/${cardCode}`);
    return res.data;
  },

  async cancelSpecialGiftCard(cardCode) {
    const res = await api.delete(`/gift-cards/special-card/${cardCode}`);
    return res.data;
  },

  async getActiveGiftCard(cardCode) {
    const res = await api.get(`/gift-cards/${cardCode}`);
    return res.data;
  },

  async useGiftCard(orderId, body) {
    const res = await api.post(`/orders/${orderId}/add-giftcard`, body);
    return res.data;
  },

  async sendGiftCard(cardCode, body, locale, tenantId, getPdfLink) {
    const res = await api.post(`/gift-cards/${cardCode}/send`, body, {
      headers: {
        "Accept-Language": locale,
        tenantId,
      },
      params: {
        getAsPdfLink: getPdfLink,
      },
    });
    return res.data;
  },

  /**
   *
   * CUSTOMER
   *
   */

  async getCustomerList(params) {
    const res = await api.get("/customer", { params });

    return res.data;
  },

  async getCustomerListParams(params) {
    return await api.get("/customer", { params });
  },

  async addAccountCustomer(accountId, customerId) {
    const res = await api.post(`/account/${accountId}/customer?remove=false`, customerId);
    return res.data;
  },

  async removeCustomer(accountId, params) {
    const res = await api.post(`/account/${accountId}/customer?remove=true`, params);
    return res.data;
  },

  async updateCustomer(customerId, body) {
    const res = await api.patch("/customer/" + customerId, body);
    return res.data;
  },
  /**
   *
   * COMPANY
   *
   */

  async getCompany(companyId) {
    const { data } = await api.get("/company", {
      params: {
        companyId,
      },
    });
    return data;
  },

  async getCompanies(filter = {}) {
    const { data } = await api.get("/company/list", {
      params: {
        ...filter,
      },
    });
    return data;
  },

  async createCompany(body) {
    const res = await api.post("/company", body);
    return res.data;
  },

  async updateCompany(companyId, body) {
    const res = await api.patch("/company/" + companyId, body);
    return res.data;
  },

  async deleteCompany(companyId) {
    const res = await api.delete("/company/" + companyId);
    return res.data;
  },

  /**
   *
   * INVOICES
   *
   */

  async getInvoiceList(params) {
    const res = await api.get("/invoice/list", { params });
    return res.data;
  },

  async getInvoice(invoiceId) {
    const res = await api.get(`/invoice/${invoiceId}`);
    return res.data;
  },

  async deleteInvoice(invoiceId) {
    const res = await api.delete(`/invoice/${invoiceId}`);
    return res.data;
  },

  async getCompanyList(params) {
    const res = await api.get("/company/list", { params });
    return res.data;
  },

  async createInvoice(body) {
    const res = await api.post("/invoice", body);
    return res.data;
  },

  async getPaymentSession(orderId) {
    const { data } = await api.get(`/orders/${orderId}/payment/fortnox`);
    return data;
  },

  async completePaymentSession(pushUrl) {
    const { data } = await api.post(pushUrl, {});
    return data;
  },

  async updateInvoice(invoiceId, body) {
    const { data } = await api.patch(`/invoice/${invoiceId}`, body);
    return data;
  },

  async reportInvoiceExcel(params) {
    return await api.get("/invoice/report-excel", {
      params,
      responseType: "blob",
    });
  },

  /**
   *
   * GROUPS
   *
   */

  async getGroups(filter = {}) {
    const { data } = await api.get("/user/group", {
      params: {
        ...filter,
      },
    });
    return data;
  },

  async createGroup(body) {
    const res = await api.post("/user/group", body);
    return res.data;
  },

  async updateGroup(groupId, body) {
    const res = await api.patch("/user/group/" + groupId, body);
    return res.data;
  },

  async deleteGroup(groupId) {
    const res = await api.delete("/user/group/" + groupId);
    return res.data;
  },

  // Wiki actions

  async listWikis(filter = {}) {
    const { data } = await api.get("/wiki", {
      params: {
        ...filter,
      },
    });
    return data;
  },

  async createWiki(body) {
    const { data } = await api.post("/wiki", body);
    return data;
  },

  async getWiki(wikiId) {
    const { data } = await api.get(`/wiki/${wikiId}`);
    return data;
  },

  async deleteWiki(wikiIds) {
    const { data } = await api.post(`/wiki/delete`, { wikiIds: wikiIds });
    return data;
  },

  async updateWiki(wikiId, body) {
    const { data } = await api.patch(`/wiki/${wikiId}`, body);
    return data;
  },

  // Node actions of a wiki

  async createNode(wikiId, body) {
    const { data } = await api.post(`/wiki/${wikiId}/node`, body);
    return data;
  },

  async getNode(wikiId, nodeId) {
    const { data } = await api.get(`/wiki/${wikiId}/node/${nodeId}`);
    return data;
  },

  async deleteNode(wikiId, nodeId) {
    const { data } = await api.delete(`/wiki/${wikiId}/node/${nodeId}`);
    return data;
  },

  async updateNode(wikiId, nodeId, body) {
    const { data } = await api.patch(`/wiki/${wikiId}/node/${nodeId}`, body);
    return data;
  },
};

export default Trainplanet;
