import Vue from "vue";
import {extend, localize, ValidationObserver, ValidationProvider} from "vee-validate";
import {
  alpha,
  alpha_dash,
  alpha_spaces,
  confirmed,
  email,
  length,
  max,
  min,
  numeric,
  required
} from "vee-validate/dist/rules";
import en from "vee-validate/dist/locale/en.json";

localize({
  // add new local and import it here
  en
});
extend("required", required);
extend("email", email);
extend("numeric", numeric);
extend("min", min);
extend("max", max);
extend("confirmed", confirmed);
extend("alpha", alpha);
extend("alpha_spaces", alpha_spaces);
extend("alpha_dash", alpha_dash);
extend("length", length);

const MOBILEREG = /^((1[3578][0-9])+\d{8})$/;

const MobileValidate = {
  getMessage(field, args) {
    return "phone is wrong";
  },
  validate(value, args) {
    return MOBILEREG.test(value);
  }
};

// extend('required', {
//   ...required,
//   message: '{_field_} can not be empty',
// });

extend("password", {
  params: ["target"],
  validate(value, {target}) {
    return value === target;
  },
  message: "Password confirmation does not match"

  // message: {
  //     en : 'Password confirmation does not match',
  // }
});

Vue.component("validation-provider", ValidationProvider);
Vue.component("validation-observer", ValidationObserver);
