export const ROLES = {
  ORDER_BASIC   : 'Role.Order.Basic',
  ORDER_PRINT   : 'Role.Order.Print',
  ORDER_VALIDATE: 'Role.Order.Validate',

  TASKS     : 'Role.Tasks',
  ACCOUNTING: 'Role.Accounting',
  INVOICING : 'Role.Invoicing',
  GIFT_CARDS: 'Role.GiftCards',

  ACCOUNT_MANAGER: 'Role.Account.Manager',

  ADMIN_BACK_OFFICE: 'Role.Admin.BackOffice',
  ADMIN            : 'Role.Admin'
};
