var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('base-material-card', _vm._g(_vm._b({
    staticClass: "v-card--material-stats",
    attrs: {
      "icon": _vm.icon
    },
    scopedSlots: _vm._u([!_vm.$slots['title'] ? {
      key: "after-heading",
      fn: function () {
        return [_c('div', {
          staticClass: "ml-auto text-right"
        }, [_c('div', {
          staticClass: "body-3 text-body font-weight-light",
          domProps: {
            "textContent": _vm._s(_vm.title)
          }
        }), _c('h2', {
          style: 'color:' + _vm.valueColor
        }, [_vm._v(" " + _vm._s(_vm.value) + " ")]), _c('h5', {
          style: 'color:' + _vm.valueColor
        }, [_vm._v(" " + _vm._s(_vm.smallValue) + " ")])])];
      },
      proxy: true
    } : null], null, true)
  }, 'base-material-card', _vm.$attrs, false), _vm.$listeners), [_vm.$slots['title'] ? _vm._t("title") : _vm._e(), _c('v-col', {
    staticClass: "px-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-icon', {
    staticClass: "ml-2 mr-1",
    attrs: {
      "color": _vm.subIconColor,
      "size": "16"
    }
  }, [_vm._v(" " + _vm._s(_vm.subIcon) + " ")]), _c('span', {
    staticClass: "caption grey--text font-weight-light",
    class: _vm.subTextColor,
    domProps: {
      "textContent": _vm._s(_vm.subText)
    }
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }