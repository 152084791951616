<template>
  <v-app>
    <Navigation v-if="isAuthenticated" />
    <Header v-if="isAuthenticated" />
    <v-main>
      <v-container fluid :fill-height="!isAuthenticated" class="pa-5 mb-15">
        <!-- Page content renders here -->
        <router-view></router-view>
        <!-- Login expired popup -->
        <v-dialog
          :value="currentRoute.query.authstate === 'retry'"
          max-width="600"
        >
          <v-card>
            <v-card-title>Login expired</v-card-title>

            <v-card-text>
              <p>
                Your login session has expired. Please renew the session to keep
                working.
              </p>
            </v-card-text>

            <v-card-actions>
              <v-btn
                @click="renewSession()"
                :loading="authenticating"
                color="accent"
                block
                >Renew session</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Global error message -->
        <v-snackbar
          :value="error"
          min-width="calc(100% - 10px)"
          :timeout="-1"
          color="red"
          top
          multi-line
          app
        >
          <v-row align="center">
            <v-col md="auto">
              <v-icon large>mdi-alert-octagon-outline</v-icon>
            </v-col>
            <v-col>
              <h3>Error</h3>
              <code class="text-subtitle-1">{{ error }}</code>
            </v-col>
          </v-row>

          <template v-slot:action="{ attrs }">
            <v-btn icon v-bind="attrs" @click="error = null">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>

        <!-- Global loading spinner -->
        <v-overlay :value="loading" absolute color="white" :opacity="0.8">
          <v-progress-circular
            indeterminate
            size="64"
            color="accent"
          ></v-progress-circular>
        </v-overlay>

        <!-- Global success message -->

        <v-snackbar
          :value="success"
          app
          color="success"
          min-width="calc(100% - 10px)"
          multi-line
          top
        >
          <v-row align="center">
            <v-col md="auto">
              <v-icon large>mdi-check-circle</v-icon>
            </v-col>
            <v-col>
              <h3>Success</h3>
              <code class="text-subtitle-1">{{ success }}</code>
            </v-col>
          </v-row>

          <template v-slot:action="{ attrs }">
            <v-btn icon v-bind="attrs" @click="success = null">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Navigation from "./components/layout/Navigation.vue";
import Header from "./components/layout/Header.vue";

export default {
  name: "App",
  components: {
    Navigation,
    Header,
  },
  data: () => ({
    authenticating: false,
  }),
  computed: {
    loading: (self) => self.$store.getters.isLoading,
    isAuthenticated: (self) => self.$store.getters["auth/isAuthenticated"],
    currentRoute: (self) => self.$route,
    error: {
      get() {
        return this.$store.getters.error;
      },
      set(error) {
        this.$store.dispatch("error", error);
      },
    },
    success: {
      get() {
        setTimeout(() => {
          this.success = null;
        }, 3000);
        return this.$store.getters.success;
      },
      set(msg) {
        this.$store.dispatch("success", msg);
      },
    },
  },
  methods: {
    async renewSession() {
      this.authenticating = true;
      await this.$store.dispatch(
        "auth/acquireToken",
        this.currentRoute.meta.scopes
      );
      await this.$router.replace(this.currentRoute.path);
      this.authenticating = false;
    },
  },
};
</script>

<style>
@import './assets/style.css';


</style>
