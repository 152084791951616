import Trainplanet from "./../../util/trainplanet.api";

const vuex = {};

vuex.state = {
  statistics: {
    statisticsTotal: {
      totalCount: 0,
      articleCount: 0,
      giftCardCount: 0,
      railPassCount:0
    },
    statisticsPerCurrency: [],
    orders: []
  }
};
vuex.getters = {
  statistics: state => state.statistics
};
vuex.mutations = {
  setStatistics(state, statistics) {
    state.statistics = statistics;
  }
};
vuex.actions = {
  async getStatistics({ commit, getters }, params) {
    const res = await Trainplanet.getStatistics(params);
    commit("setStatistics", res);
  }
};

export default {
  namespaced: true,
  state: vuex.state,
  getters: vuex.getters,
  mutations: vuex.mutations,
  actions: vuex.actions
};
