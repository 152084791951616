const getters = {
  isLoading: state => state.loading,
  error: state => state.error,
  success: state => state.success,
  allTenants: state => state.allTenants
};

export default {
  getters
};
