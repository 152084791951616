const state = {
  loading: false,
  error: null,
  success: null,
  drawer: null,
  tenants: [],
};

export default {
  state,
};
