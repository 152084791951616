<template>
  <v-navigation-drawer
    v-model="drawer"
    mobile-breakpoint="960"
    width="250"
    v-bind="$attrs"
    light
    app
  >
    <v-list dense>
      <v-list-item style="border: none !important">
        <a href="/orders/search"> <img class="logo" src="../../assets/mango-header-logo.png"/></a>
      </v-list-item>
      <v-list-item class="justify-center" style="border: none !important">
        <h2 v-if="isDevEnvironment" class="orange--text font-weight-bold" v-text="'Test Version'" />
      </v-list-item>
    </v-list>
    <div class="sidemenu-loader" v-if="permissionLoader">
      <v-progress-circular indeterminate color="#f4846e"></v-progress-circular>
    </div>
    <v-list v-if="!permissionLoader" nav style="border-top: 1px solid #ebebeb">
      <v-list-item-group
        v-for="(route, i) in routes"
        :key="i"
        class="font-weight-bold navi"
        active-class=" primary--text"
      >
        <!-- Child route -->
        <v-list-group
          v-if="route.children && route.children.length > 0"
          :prepend-icon="route.meta.icon"
          :group="route.path"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ route.meta.title }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            class="ml-4 pl-2"
            v-for="(child, f) in route.children"
            :key="f"
            :to="route.path + '/' + child.path"
          >
            <v-list-item-icon>
              <v-icon>{{ child.meta.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ child.meta.title }}</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <!-- Parent route -->
        <v-list-item v-if="!route.children" link :to="route.path">
          <v-list-item-icon>
            <v-icon>{{ route.meta.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-title>{{ route.meta.title }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
      <v-list-item-group class="font-weight-bold navi">
        <a
          href="https://trainplanet.sharepoint.com/:x:/s/backofficeadmin_team/EfFlPZqBuEFKgZLY1B01Lb0BEtUbSjnzWZcl7HcPFAmuxQ"
          target="_blank"
          style="text-decoration:none;font-weight:0"
        >
          <v-list-item v-if="isBackOfficeAdmin">
            <v-list-item-icon>
              <v-icon>mdi-file-document</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Complimentary Pass</v-list-item-title>
          </v-list-item></a
        >
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Navigation",
  data: () => ({
    permissionLoader: false,
    routes: [],
    attrs: {
      boilerplate: true,
      elevation: 2
    }
  }),
  async mounted() {
    this.routes = await this.getRoutes();
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "auth/isAuthenticated",
      isBackOfficeAdmin: "auth/isBackOfficeAdmin"
    }),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      }
    },
    isDevEnvironment() {
      let subdomain = /:\/\/([^\/]+)/.exec(window.location.href)[1].split(".")[0];

      return (
        subdomain.startsWith("preprod") ||
        subdomain.startsWith("localhost") ||
        subdomain.startsWith("dev")
      );
    }
  },
  methods: {
    async checkMicrosoftPermission(routes) {
      this.permissionLoader = true;
      await this.$store.dispatch("loading", true);

      const res = await this.$store.dispatch("auth/getUser");
      const roles = res.roles;
      await this.$store.dispatch("loading", false);

      const filtered_routes = routes
        .map(route => {
          if (route.children && route.children.length > 0) {
            const new_route_children = route.children.filter(child =>  (child.meta.roles.some(role => roles.includes(role)))); // prettier-ignore
            return { ...route, children: new_route_children };
          } else {
            if (route.meta.roles) {
              return route.meta.roles.some(role => roles.includes(role)) ? route : null;
            } else {
              return route;
            }
          }
        })
        .filter(x => x);

      this.permissionLoader = false;
      return filtered_routes;
    },
    async getRoutes() {
      let { routes } = this.$router.options;
      routes = routes.filter(route => route.meta.menus && route.meta.menus.includes("nav"));

      /* eslint-disable no-param-reassign */
      routes.forEach(route => {
        if (route.children) {
          route.children = route.children.filter(child => child.meta.showInMenu);
        }
      });

      return await this.checkMicrosoftPermission(routes);
    }
  }
};
</script>

<style>
.sidemenu-loader {
  display: flex;
  justify-content: center;
}
.theme--dark.v-list-item--active::before {
  background: none;
}
.logo {
  width: 210px;
  padding: 10px;
  padding-bottom: 0;
}
.v-list-item__icon {
  margin-right: 15px !important;
}
.navi .v-icon {
  color: #f4846e !important;
}
.v-list-item__title {
  color: rgb(99, 99, 99);
}
.v-list .v-list-item {
  border: 1px solid #ebebeb;
}
.v-list-item-group {
  margin-bottom: 5px;
}
.theme--light.v-navigation-drawer {
  background-color: #f5f5f5 !important;
}
.navi .v-list-item {
  background: #fff;
}
.v-list-item__icon {
  margin-left: 10px !important;
}

.v-list-group__items .v-list-item__icon {
  margin: 12px 0;
}
</style>
