import contentRoutes from "@/router/routes/contentRoutes";
import store from "@/store";
import Vue from "vue";
import VueRouter from "vue-router";
import accountingRoutes from "./routes/accountingRoutes";
import giftCardRoutes from "./routes/giftCardRoutes";
import orderRoutes from "./routes/orderRoutes";
import printRoutes from "./routes/printRoutes";
import companyRoutes from "./routes/companyRoutes";
import systemRoutes from "./routes/systemRoutes";
import userRoutes from "./routes/userRoutes";
import metaRoutes from "./routes/metaRoutes";
import taskRoutes from "@/router/routes/taskRoutes";
import wikiRoutes from "@/router/routes/wikiRoutes";

Vue.use(VueRouter);

const routes = [
  ...metaRoutes,
  taskRoutes,
  orderRoutes,
  printRoutes,
  giftCardRoutes,
  companyRoutes,
  contentRoutes,
  systemRoutes,
  accountingRoutes,
  userRoutes,
  wikiRoutes,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// Auth redirect middleware
router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters["auth/isAuthenticated"];

  try {
    if (to.path !== "/login" && !isAuthenticated) {
      next("/login");
    } else if (to.path === "/login" && isAuthenticated) {
      next("/");
    } else {
      next();
    }
  } catch (error) {
    next({
      name : to.name,
      query: {
        authstate: "retry"
      }
    });
  }
});

// Page title middleware
router.afterEach(to => {
  const DEFAULT_TITLE = "MANGO";
  const route = to;

  if (!route.meta.originalTitle) {
    // Save the original title since this is the one we want to calculate comming titles from
    route.meta.originalTitle = route.meta.title;
  }

  route.meta.title = route.meta.originalTitle.replace(
    /:\w+/g,
    placeholder => route.params[placeholder.substring(1)]
  ); // Set title

  Vue.nextTick(() => {
    document.title = `${route.meta.title} - ${DEFAULT_TITLE}` || DEFAULT_TITLE; // Replace the tab-title
  });
});

export default router;
