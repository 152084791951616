import {ROLES} from "@/store/modules/roles";

const userRoutesRoles = [ROLES.ACCOUNT_MANAGER, ROLES.ADMIN_BACK_OFFICE, ROLES.ADMIN];
const userRoutesScopes = [process.env.VUE_APP_MICROSOFT_API_URL + "/Scope.Account.Manager"];

export default {
  path     : "/user",
  name     : "user",
  props    : false,
  component: () => import(/* webpackChunkName: "orders" */ "@/views/User/Index.vue"),
  meta     : {
    title     : "Users",
    icon      : "mdi-account-group",
    menus     : ["nav"],
    showInMenu: true,
    roles     : userRoutesRoles
  },
  children : [
    {
      path     : "create-account",
      name     : "create-account",
      props    : true,
      component: () =>
        import(/* webpackChunkName: "orders-print" */ "@/views/User/Account/account-register.vue"),
      meta     : {
        title     : "Create Account",
        showInMenu: true,
        icon      : "mdi-account-plus-outline",
        scopes    : userRoutesScopes,
        roles     : userRoutesRoles
      }
    },
    {
      path     : "search-account",
      name     : "search-account",
      props    : true,
      component: () =>
        import(/* webpackChunkName: "orders-print" */ "@/views/User/Account/search-account.vue"),
      meta     : {
        title     : "Search Account",
        showInMenu: true,
        icon      : "mdi-account-search-outline",
        scopes    : userRoutesScopes,
        roles     : userRoutesRoles
      }
    },
    {
      path     : "groups",
      name     : "groups",
      props    : true,
      component: () =>
        import(/* webpackChunkName: "orders-print" */ "@/views/User/Group/Group.vue"),
      meta     : {
        title     : "Groups",
        showInMenu: true,
        icon      : "mdi-account-outline",
        scopes    : userRoutesScopes,
        roles     : userRoutesRoles
      }
    },
    {
      path     : ":id",
      name     : "account-detail",
      props    : true,
      component: () =>
        import(/* webpackChunkName: "orders-print" */ "@/views/User/Account/account-tab.vue"),
      meta     : {
        title     : "Account Detail",
        showInMenu: false,
        icon      : "mdi-account-group-outline",
        scopes    : userRoutesScopes,
        roles     : userRoutesRoles
      }
    },
  ]
};
